// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>
import * as Turbo from '@hotwired/turbo'
import {initializeSwiper} from '@/initializers/swiper'
import {initializeMenu} from '@/initializers/menu'
import {initializetoggleMenu} from '@/initializers/menu'
import {initializeForm} from '@/initializers/form'
import '../controllers'

document.addEventListener('turbo:load', function (event) {
  initializeSwiper();
  initializeMenu();
  initializetoggleMenu();
  initializeForm();
});
Turbo.start()

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
import * as bootstrap from 'bootstrap'
